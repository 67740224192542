/* eslint-disable import/no-unresolved */
import { graphql, useStaticQuery } from 'gatsby'

const downloadInfo = {
  get() {
    const { 
      wordpressPage
     } = useStaticQuery(graphql`
      {
        wordpressPage(wordpress_id: {eq: 1026}) {
          path

          acf {
            meta {
              description_filters
              text_read_message
            }
          }
        }
      }
    `)
  
    return wordpressPage
  }
}

export default downloadInfo