/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Blog
import Download from 'components/related/Download'
import BlogIk, { BlogConsumer, BlogPagination } from 'components/shared/BlogIk'
//import BlogFilters from 'components/elements/BlogFilters'

// Components
import { SEO, ParseContent } from 'components/shared'
import Breadcrumb from 'components/elements/BreadCrumb'
import Layout from 'components/layout/Layout'


const Hero = styled.section`
  margin-top: -10px;
  
  @media screen and (max-width: 992px){
    background-size: cover!important;
  }
`

const HeroContent = styled.div`
  padding-top: 155px;
  padding-bottom: 90px;
`

const FiltersContent = styled(ParseContent)`
  & p {
    margin: 0;
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
  pageContext
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <Hero className="hero-image-small" style={{
          backgroundImage: `url(${acf.banner.background.localFile.childImageSharp.fluid.src})`
        }}>
        <HeroContent className="container px-3">
          <div className="mt-5 mb-3">
            <ParseContent content={acf.banner.description} />
          </div>
          <Breadcrumb data={title} className="py-0" pageContext={pageContext} />
        </HeroContent>
      </Hero>

      <div className="container pb-5 mb-5">
        <BlogIk
          settings={{
            limit: parseFloat(acf.meta.amount_posts),
            postType: 'downloads',
            usePagination: true
          }}
        >
          <BlogConsumer>
            {({ hasPosts, posts }) => {
              return (
                <div>
                  <div className="my-4 d-md-flex align-items-center">
                    {/* <FiltersContent content={acf.meta.description_filters} />
                    <div className="d-flex mt-md-0 mt-3">
                      <BlogFilters />
                    </div> */}
                  </div>

                  {hasPosts && (
                    <Download posts={posts} />
                  )}

                  {!hasPosts && (
                    <ParseContent className="py-5" content={acf.meta.description_no_results} />
                  )}

                  <div className="mt-4 d-flex justify-content-end">
                    <BlogPagination />
                  </div>
                </div>
              )
            }}
          </BlogConsumer>
        </BlogIk>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          description
          background {
          localFile {
            publicURL
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 2000, quality:100) {
                src
              }
            }
          }
        }
        }

        meta {
          description_filters
          description_no_results
          amount_posts
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate