/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ParseContent } from 'components/shared'
import arrowRight from 'img/arrow-right.svg'
import clockIcon from 'img/icon-clock.svg'

// Data
import downloadInfo from 'src/graphql/downloadInfo'
import Plaatjie from '@ubo/plaatjie'

const Container = styled(Link)`
  display: block;
  width: 100%;
  margin: 20px 0;
  color: ${(props) => props.theme.color.text.main};

  & h3 {
    font-size: ${(props) => props.theme.font.size.m};
    font-weight: ${(props) => props.theme.font.weight.l};
    line-height: ${(props) => props.theme.font.size.xm};
  }

  &:hover {
    text-decoration: none !important;
  }
`

const Time = styled.div`
  background: #012E58;
  padding: 8px 10px;
  color: white;
  border-radius: 15px;
  display: flex;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.sm};
  font-family: ${(props) => props.theme.font.family.secondary};
  letter-spacing: .7px;
  width: fit-content;
  position: relative;
  margin-top: -15px;
  margin-right: 20px;
  margin-left: auto;
`

const Intro = styled(ParseContent)`
  font-family: ${(props) => props.theme.font.family.secondary};
  display: block;
  display: -webkit-box;
  height: 65px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const LoadMore = styled.span`
  color: ${(props) => props.theme.color.text.secondary}!important;
  font-size: ${(props) => props.theme.font.size.sm};
  line-height: ${(props) => props.theme.font.size.m};
  font-family: ${(props) => props.theme.font.family.bold};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: all 300ms ease-in;
  
  &:hover {
    color: ${(props) => props.theme.color.text.secondary};
    text-decoration: none !important;
    cursor: pointer;
    border-bottom: 1px solid ${(props) => props.theme.color.text.secondary};
  }
`

export default function SingleDownload({ fields }) {
  const downloadFields = downloadInfo.get()

  return (
    <Container to={fields.path}>
      <Plaatjie image={fields.acf.media.image} style={{height: 250, width: '100%',borderRadius: 5, objectFit: 'cover'}} alt={fields.title} />
      {fields.acf.meta.readtime && (
        <Time>
          <img src={clockIcon} alt='clock' />
          <span className='mx-2'>{fields.acf.meta.readtime}</span>
        </Time>
      )}
      <div className='p-2 mt-3'>
        <h3>{fields.title}</h3>
        <div className="mt-4 font-size-m color-main">
          <Intro content={fields.acf.meta.intro} />
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <LoadMore className="d-flex">
          <span>{downloadFields.acf.meta.text_read_message}</span>
          <img className='ml-3' src={arrowRight} alt='arrow right' />
        </LoadMore>
      </div>
    </Container>
  )
}